import React from "react";
import { withRouter } from "react-router-dom";
import {
  FormInput,
} from "shards-react";
import { Button } from 'antd';
import AuthService from "../utils/AuthService";


const Auth = new AuthService();

class Login extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      user: '',
      pass: '',
      loginFailed: false,
      onLogin: false,
      logged: false,
      notification: {},
    };
  }

  componentDidMount() {
    if (this.props.location.search === "?nomatch=true") {
      Auth.logout();
      this.props.history.push('/login');
    } else {
      this.checkState();
    }
  }

  async checkState() {

    var logeado = await Auth.loggedIn();
    if (logeado) {
      this.redireccionaLogged();
    }
  }

  redireccionaLogged = () => {
    window.location.href = '/emprendimientos';
  }

  login = async () => {
    this.setState({ onLogin: true });
    var response = await Auth.login(this.state.user, this.state.pass);
    console.log('STATE', this.state)
    console.log('Response', response)
    if (response && response.token != undefined) {
      this.redireccionaLogged();
    } else {
      this.setState({ loginFailed: true, onLogin: false });
    }
  }

  render() {
    return (
      <div>
        <form className="cajaLogin">
          <div className='logo' />
          <div >
            {this.state.loginFailed ? <Loginfailed /> : null}

            <div className="form-group formLogin">
              <label htmlFor="feEmailAddress">Usuario</label>
              <FormInput
                id="feEmailAddress"
                type="email"
                onChange={evt => this.setState({ user: evt.target.value })}
                placeholder="Email"
              />
            </div>
            <div className="form-group formLogin">
              <label htmlFor="fePassword">Contraseña</label>
              <FormInput
                id="fePassword"
                type="password"
                onChange={evt => this.setState({ pass: evt.target.value })}
                placeholder="Password"
              />
            </div>
          </div>
          <br />
          <Button className='btn btn-primary btn-block' loading={this.state.onLogin} onClick={() => this.login()}>Ingresar</Button>

        </form>
      </div>
    );
  }
}
const Loginfailed = () => (
  <div className="falloLogin">
    <span >¡Ups!</span><br />
    El Usuario o la Contraseña<br />es incorrecto.
  </div>
)

export default withRouter(Login);